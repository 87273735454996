<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>
      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12" md="12">
                      <ACC_ChartAccount
                          :value="accounting_id"
                          :class="{'req-star': !accounting_id}"
                          label="Рахунок бух.обліку"
                          @autocompleteChange="chartOfAccountChange"
                      />
                    </v-col>
                    <v-col cols="12" v-if="filters.account_detail_1_type">
                      <ACC_Subconto
                          v-model="filters.account_detail_1_value"
                          :computed-action="subconto_type_select_setting[filters.account_detail_1_type].computed_action"
                          :computed-getter="subconto_type_select_setting[filters.account_detail_1_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :show-select-on-focus="false"
                          label="Субконто 1 (для юр.обліку)"
                          :required="true"
                          :clearable="true"
                          :select-button-object="{
                                name: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[filters.account_detail_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                          @autocompleteChange="detail1Change"
                      />
                    </v-col>
                    <v-col cols="12" v-if="filters.account_detail_2_type">
                      <template v-if="filters.account_detail_1_type === 'contractor' && filters.account_detail_2_type === 'contract'">
                        <ACC_Subconto
                            v-model="filters.account_detail_2_value"
                            :computed-action="subconto_type_select_setting[filters.account_detail_2_type].computed_action"
                            :computed-getter="subconto_type_select_setting[filters.account_detail_2_type].computed_getter"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            label="Субконто 2 (для юр.обліку)"
                            :required="true"
                            :clearable="true"
                            :owner_id="filters.account_detail_1_value"
                            :sync-action-props="true"
                            :select-button-object="{
                                name: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true,
                                action_props: {owner_id: filters.account_detail_1_value},
                                sync_action_props: true
                              }"
                            @autocompleteChange="detail2Change"
                        />
                      </template>
                      <template v-else>
                        <ACC_Subconto
                            v-model="filters.account_detail_2_value"
                            :computed-action="subconto_type_select_setting[filters.account_detail_2_type].computed_action"
                            :computed-getter="subconto_type_select_setting[filters.account_detail_2_type].computed_getter"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            :show-select-on-focus="false"
                            label="Субконто 2 (для юр.обліку)"
                            :required="true"
                            :clearable="true"
                            :select-button-object="{
                                name: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[filters.account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                            @autocompleteChange="detail2Change"
                        />
                      </template>
                    </v-col>
                    <v-col cols="12" v-if="filters.account_detail_3_type">
                      <ACC_Subconto
                          v-model="filters.account_detail_3_value"
                          :computed-action="subconto_type_select_setting[filters.account_detail_3_type].computed_action"
                          :computed-getter="subconto_type_select_setting[filters.account_detail_3_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :show-select-on-focus="false"
                          label="Субконто 3 (для юр.обліку)"
                          :required="true"
                          :clearable="true"
                          :select-button-object="{
                                name: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[filters.account_detail_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                          @autocompleteChange="detail3Change"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 215mm;"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report table-report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Картка рахунку)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead>
            <tr id="header">
              <th rowspan="2" style="min-width: 80px; width: 80px !important;">
                Період
              </th>
              <th rowspan="2" style="min-width: 170px; width: 170px !important;">
                Документ
              </th>
              <th rowspan="2" style="min-width: 180px; width: 180px !important;">
                Аналітика Дт
              </th>
              <th rowspan="2" style="min-width: 180px; width: 180px !important;">
                Аналітика Кт
              </th>
              <th rowspan="1" colspan="2" style="min-width: 180px; width: 180px !important;">
                Дебет
              </th>
              <th rowspan="1" colspan="2" style="min-width: 180px; width: 180px !important;">
                Кредит
              </th>
              <th rowspan="2" style="min-width: 100px; width: 100px !important;">
                Поточне сальдо
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Рахунок
              </th>
              <th colspan="1" rowspan="1" style="min-width: 110px; width: 110px; padding: 0;">
                Сума
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Рахунок
              </th>
              <th colspan="1" rowspan="1" style="min-width: 110px; width: 110px; padding: 0;">
                Сума
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="`group-${idx}`"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td style="width: 85px; min-width: 85px">{{ item.balance_start_debt | formatNumber }}</td>
              <td style="width: 85px; min-width: 85px">{{ item.balance_start_cred | formatNumber }}</td>
              <td style="width: 85px; min-width: 85px">{{ item.turnover_debt | formatNumber }}</td>
              <td style="width: 85px; min-width: 85px">{{ item.turnover_cred | formatNumber }}</td>
              <td style="width: 85px; min-width: 85px">{{ item.balance_end_debt | formatNumber }}</td>
              <td style="width: 85px; min-width: 85px">{{ item.balance_end_cred | formatNumber }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td style="min-width: 80px; width: 80px !important;">{{ item.date | formatDate }}</td>
              <td style="min-width: 170px; width: 170px; text-align: left">
                {{ item.document_representation }}
              </td>
              <td style="min-width: 180px; width: 180px">
                <div v-if="item.debit_text_value_1 !== '[...<Не визначено>...]'">
                  {{ item.debit_text_value_1 }}
                </div>
                <div v-if="item.debit_text_value_2 !== '[...<Не визначено>...]'">
                  {{ item.debit_text_value_2 }}
                </div>
                <div v-if="item.debit_text_value_3 !== '[...<Не визначено>...]'">
                  {{ item.debit_text_value_3 }}
                </div>
              </td>
              <td style="min-width: 180px; width: 180px">
                <div v-if="item.credit_text_value_1 !== '[...<Не визначено>...]'">
                  {{ item.credit_text_value_1 }}
                </div>
                <div v-if="item.credit_text_value_2 !== '[...<Не визначено>...]'">
                  {{ item.credit_text_value_2 }}
                </div>
                <div v-if="item.credit_text_value_3 !== '[...<Не визначено>...]'">
                  {{ item.credit_text_value_3 }}
                </div>
              </td>
              <td style="min-width: 80px; width: 80px" :style="item.debit_sum ? 'font-weight: bold;' : ''">
                {{ item.debit_code }}
              </td>
              <td style="min-width: 100px; width: 100px" :style="item.debit_sum ? 'font-weight: bold;' : ''">
                {{ item.debit_sum === 0 ? '' : formatNumber(item.debit_sum) }}
              </td>
              <td style="min-width: 80px; width: 80px" :style="item.credit_sum ? 'font-weight: bold;' : ''">
                {{ item.credit_code }}
              </td>
              <td style="min-width: 100px; width: 100px" :style="item.credit_sum ? 'font-weight: bold;' : ''">
                {{ item.credit_sum === 0 ? '' : formatNumber(item.credit_sum) }}
              </td>
              <td style="min-width: 110px; width: 110px">
                <div style="display: flex">
                  <div style="flex: 0 0 18px">
                    {{ item.balance_letter }}
                  </div>
                  <div style="flex: 1">
                    {{ item.balance | formatNumber }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import accountingReportAPI from "@/utils/axios/accounting/reports"
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account"
import {ALERT_SHOW} from "@/store/actions/alert";
import {getSubcontoType} from "@/utils/icons"
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import {subconto_type_select_setting} from "@/utils/accounting";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {formatNumber} from "@/filters";

export default {
  name: "AccountingCardReportByCode",
  mixins: [ReportHouseAccountingComponentMixin],
  props: [
    'auto_generate',
    'payload_int',
    'payload_add_int_1',
    'payload_add_int_2',
    'payload_add_int_3',
    'payload_add_str_1',
    'payload_add_str_2',
    'payload_add_str_3',
  ],
  components: {
    ACC_ChartAccount: () => import("@/components/accounting/autocomplite/modal/ACC_Chart_of_account"),
    ACC_Subconto: () => import("@/components/accounting/autocomplite/modal/ACC_Subconto")
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      subconto_type_select_setting,
      charts: [],
      accounting_id: null,
      filters: {
        account_detail_1_value: null,
        account_detail_2_value: null,
        account_detail_3_value: null,
        account_detail_1_type: null,
        account_detail_2_type: null,
        account_detail_3_type: null,
      },
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      grouping: [],
      auto_generate_watcher: null,
      payload_int_watcher: null
    }
  },
  methods: {
    formatNumber,
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    chartOfAccountChange(payload, after=false) {
      this.accounting_id = (payload || {})?.value || null
      this.$nextTick(() => {
        if (!this.accounting_id) {
          this.grouping = []
          this.filters.account_detail_1_type = null
          this.filters.account_detail_1_value = null
          this.filters.account_detail_2_type = null
          this.filters.account_detail_2_value = null
          this.filters.account_detail_3_type = null
          this.filters.account_detail_3_value = null
          return
        }
        const chart = this.charts.find(c => c.id === this.accounting_id) || {}
        const ch_details = chart.details || []
        const grouping = []

        ch_details.forEach(i => {
          grouping.push(
              {text: getSubcontoType(i.detail_type), value: `detail_${i.order}_key`, on: true}
          )
        })

        let details = {}
        if (payload) {
          if ('text' in payload && 'value' in payload) {
            details = this.get_account_details(payload.value)
          } else {
            details = this.get_account_details(payload.id)
          }
          this.filters.account_detail_1_type = details.account_detail_1.type
          this.filters.account_detail_1_value = null
          this.filters.account_detail_2_type = details.account_detail_2.type
          this.filters.account_detail_2_value = null
          this.filters.account_detail_3_type = details.account_detail_3.type
          this.filters.account_detail_3_value = null
        } else {
          this.filters.account_detail_1_type = null
          this.filters.account_detail_1_value = null
          this.filters.account_detail_2_type = null
          this.filters.account_detail_2_value = null
          this.filters.account_detail_3_type = null
          this.filters.account_detail_3_value = null
        }

        this.grouping = grouping

        if (after) {
          this.filters.account_detail_1_value = this.payload_add_int_1
          this.filters.account_detail_2_value = this.payload_add_int_2
          this.filters.account_detail_3_value = this.payload_add_int_3
        }
      })
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    detail1Change(payload) {
      this.filters.account_detail_1_value = (payload || {})?.value || null
    },
    detail2Change(payload) {
      this.filters.account_detail_2_value = (payload || {})?.value || null
    },
    detail3Change(payload) {
      this.filters.account_detail_3_value = (payload || {})?.value || null
    },
    generate_email_send() {
      if (this.date_start && this.date_end && this.accounting_id) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          accounting_id: this.accounting_id,
          filters: Object.assign({}, this.filters, {grouping: grouping}),
          email: this.email_address,
        }
        accountingReportAPI.accounting_card_report_by_code_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період має бути заповнений',
          color: 'error'
        })
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end && this.accounting_id) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          accounting_id: this.accounting_id,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        accountingReportAPI.accounting_card_report_by_code_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `accounting_card_report_by_code_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end && this.accounting_id) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          accounting_id: this.accounting_id,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        accountingReportAPI.accounting_card_report_by_code(payload)
            .then(response => response.data)
            .then(data => {
              this.items = data
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl -1)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Дата та Рахунок - мають бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    watch_auto_generate() {
      this.auto_generate_watcher = this.$watch(
          'auto_generate',
          {
            immediate: true,
            handler(payload) {
              if (this.accounting_id && payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_payload_int() {
      this.payload_int_watcher = this.$watch(
          'payload_int',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.accounting_id = payload

                this.chartOfAccountChange({value: payload, text: ''}, this.auto_generate)

                if (this.auto_generate) {
                  this.generate_report()
                }
              }
            }
          }
      )
    },
  },
  created() {
    chartOfAccountAPI.get_all()
        .then(response => response.data)
        .then(data => {
          this.charts = data

          this.watch_auto_generate()
          this.watch_payload_int()
        })
  },
  beforeDestroy() {
    if (this.auto_generate_watcher) {
      this.auto_generate_watcher()
    }
    if (this.payload_int_watcher) {
      this.payload_int_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>

.page-a4-landscape-auto {
  width: 1160px !important;
  position: relative;
}


</style>